import { ClubApp } from '@aaa/emember/types';
import { routes as NorthamptonRoutes } from './routings/northampton.routing';
import { routes as HoosierRoutes } from './routings/hoosier.routing';
import { routes as ShelbyRoutes } from './routings/shellby.routing';
import { routes as MidStatesRoutes } from './routings/midstates.routing';
import { routes as NorthwayRoutes } from './routings/northway.routing';
import { routes as SouthJersey } from './routings/southjersey.routing';
import { environment } from '../../../environments/environment';
import { Routes } from '@angular/router';

const routeRecord: Partial<{ [key in ClubApp]: Routes }> = {
  [ClubApp.Northampton]: NorthamptonRoutes,
  [ClubApp.Hoosier]: HoosierRoutes,
  [ClubApp.Shelby]: ShelbyRoutes,
  [ClubApp.MidStates]: MidStatesRoutes,
  [ClubApp.Northway]: NorthwayRoutes,
  [ClubApp.SouthJersey]: SouthJersey,
  // [ClubApp.Colorado]: [],
  // [ClubApp.MidStates]: [],
  // [ClubApp.Northway]: [],
};

export const appRoutes = routeRecord[environment.clubId];

console.table(appRoutes);

export const routes = appRoutes ? Object.values(appRoutes) : [];
