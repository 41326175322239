import { ActivatedRouteSnapshot, CanActivateChildFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const defaultRouting: CanActivateChildFn = ({ queryParams }: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const { page = '' } = queryParams || {};

  if (page === 'joinrenewalhome') {
    router.navigate(['join'], { queryParams });
    return false;
  }

  if (page === 'ClubServices11') {
    router.navigate(['account'], { queryParams });
    return false;
  }

  if (page === 'ClubServices10') {
    router.navigate(['renew'], { queryParams });
    return false;
  }

  if (page === 'ClubServices12') {
    router.navigate(['gift-offers'], { queryParams });
    return false;
  }

  return true;
};

export default defaultRouting;
