import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ava-notification',
  template: `
    @if (options.closeButton) {
      <button (click)="remove()" type="button" class="toast-close-button" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    }

    @if (title) {
      <div [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}

        @if (duplicatesCount) {
          [{{ duplicatesCount + 1 }}]
        }
      </div>
    }

    @if (message && options.enableHtml) {
      <div role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
    }

    @if (message && !options.enableHtml) {
      <div role="alert" [class]="options.messageClass" [attr.aria-label]="message">{{ message }}</div>
    }

    <ng-container #viewComponent></ng-container>

    @if (options.progressBar) {
      <div><div class="toast-progress" [style.width]="width + '%'"></div></div>
    }
  `,
  styles: `
    :host {
      display: block;
    }
  `,
})
export class NotificationComponent extends Toast {
  @ViewChild('viewComponent', { read: ViewContainerRef }) viewComponentRef: ViewContainerRef | null = null;

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}
