import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { PriceOffersActions } from './price-offers.actions';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import {
  MembershipCampanaGetPricingEvent,
  MembershipCampanaLevel,
  MembershipCampanaMethod,
} from '@aaa/interface-joinRenew-membership-membershipCampana';
import { catchError, map } from 'rxjs/operators';
import { RequestError, RequestErrorType } from '../generic-errors';
import { getSaveCacheSettings } from '../utils/get-cache-settings';
import { CampanaType } from '../campana.type';
import { MembershipLevelOffer, MembershipsLevel } from './price-offers.model';
import { MembershipOfferItem } from './helpers/types';
import { getCampanaMembershipType, getCampanaTowingMiles } from '../campana-utils';

@Injectable({ providedIn: 'root' })
export class PriceOffersCampanaSystemEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Northway]));
  executeService = inject(ExecuteService);

  loadPricePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceOffersActions.load),
      switchMap(() => {
        const requestPayload: MembershipCampanaGetPricingEvent = {
          cacheSettings: getSaveCacheSettings(),
          method: MembershipCampanaMethod.GET_PRICING,
        };

        return this.executeService.membershipQuery<CampanaType.PricePreviewsResponseObject>(requestPayload).pipe(
          map(({ response, error }) => {
            if (error) {
              return PriceOffersActions.loadFailed({ error: new RequestError(RequestErrorType.Error) });
            }

            const levelOffers: MembershipLevelOffer = response.pricingData
              .filter(({ personId }) => personId === '00')
              .reduce((offers, { serviceLevel, price }) => {
                const membershipType = getCampanaMembershipType(serviceLevel);
                const offerItem: MembershipOfferItem[] = [];

                offerItem.push({
                  offering: 'primary',
                  code: membershipType,
                  description: serviceLevel,
                  amount: price,
                  selectedByDefault: true,
                  conditions: [],
                });

                const associateOfferItem = response.pricingData.find(
                  (price) => price.personId === '01' && price.serviceLevel === serviceLevel,
                );

                if (associateOfferItem) {
                  offerItem.push({
                    offering: 'associate',
                    code: membershipType,
                    description: serviceLevel,
                    amount: associateOfferItem.price,
                    selectedByDefault: true,
                    conditions: [],
                  });
                }

                offers[membershipType] = offerItem;

                return offers;
              }, {} as MembershipLevelOffer);
            const levels: MembershipsLevel[] = response.pricingData
              .filter(({ personId }) => personId === '00')
              .map(({ serviceLevel }) => {
                return {
                  name: serviceLevel,
                  level: serviceLevel.toUpperCase(),
                  membershipType: getCampanaMembershipType(serviceLevel),
                  rv: serviceLevel === MembershipCampanaLevel.PlusRV,
                  towMiles: getCampanaTowingMiles(serviceLevel),
                };
              });

            return PriceOffersActions.loadSucceeded({ levelOffers, levels, rawLevelOffers: levelOffers });
          }),
          catchError((err) => {
            return of(err);
          }),
        );
      }),
    ),
  );
}
