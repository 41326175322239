import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetaWindow } from '../interfaces/window';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { map } from 'rxjs/operators';

export interface Office {
  _type: 'office';
  id: string;
  title: string;
  status: '0' | '1';
  pathName: string;
  phones: {
    label: string;
    phone: string;
  }[];
  addresses: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  }[];
}

export interface Offices {
  [key: string]: Office;
}

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  window: MetaWindow;
  private officesRef: AngularFirestoreCollection<Office>;
  public offices$: Observable<Office[]>;

  constructor(
    private afs: AngularFirestore,
    private httpClient: HttpClient,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow;
    this.officesRef = this.afs
      .collection('wss-aaa-web')
      .doc(this.window.metaData.clubId)
      .collection('data')
      .doc('drupal')
      .collection('nodes', (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('_type', '==', 'office');
        return query;
      });
    this.offices$ = this.officesRef.snapshotChanges().pipe(
      map((offices: DocumentChangeAction<Office>[]) => {
        return offices.map((result) => {
          if (result) {
            const data = result.payload.doc.data();
            data.id = result.payload.doc.id;
            return data;
          }
          return result;
        });
      }),
    ) as Observable<Office[]>;
  }

  // getOffices(): Observable<Offices> {
  // const officesUrl = this.window.metaData.origin || "" + "/data/office/all"
  // return this.httpClient.get<Offices>(officesUrl)
  // return this.officesRef.snapshotChanges().subscribe()
  // }

  /*
    handleError(error: HttpErrorResponse): ObservableInput<never> {
      if (error.error instanceof ErrorEvent) {
        console.error("An error occurred:", error.error.message)
      } else {
        console.log("An different error occurred:")
        console
          .error("Backend returned code " + error.status, "body was: " + error.error)
      }
      return throwError("Something bad happened; please try again later.")
    }
  */
}
