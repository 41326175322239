import { CanActivateChildFn } from '@angular/router';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStore } from '@aaa/emember/store-types';
import { JoinActions, JoinPage } from '@aaa/emember/store-join';

export const setPageJoin =
  (page: JoinPage): CanActivateChildFn =>
  () => {
    const store = inject(Store<AppStore>);

    return of(true).pipe(tap(() => store.dispatch(JoinActions.setPage({ page }))));
  };

export default setPageJoin;
