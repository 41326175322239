import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileManagerComponent } from './file-manager.component';
import { FileManagerListComponent } from './file-manager-list/file-manager-list.component';
import { FileManagerUploadComponent } from './file-manager-upload/file-manager-upload.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FileManagerService } from './file-manager.service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FileManagerPreviewComponent } from './file-manager-preview/file-manager-preview.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  declarations: [
    FileManagerComponent,
    FileManagerListComponent,
    FileManagerUploadComponent,
    FileManagerPreviewComponent,
  ],
  imports: [
    CommonModule,
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    NzModalModule,
    NzListModule,
    NzAutocompleteModule,
    FormsModule,
    NzInputModule,
  ],
  exports: [FileManagerComponent],
  providers: [FileManagerService],
})
export class FileManagerModule {}
