export function interfaceJoinRenewPaymentPaymentShift4(): string {
  return 'interface-join-renew-payment-payment-shift4';
}

import { EventPayload, Operation, OperationEventResponse } from '@aaa/interface-joinRenew-joinRenewLib';

export enum PaymentShift4Method {
  OPERATION_EXECUTE = 'OPERATION_EXECUTE',
}

export interface PaymentShift4EventPayload extends EventPayload {
  method: PaymentShift4Method;
}

export interface PaymentShift4OperationExecuteEventPayload extends PaymentShift4EventPayload {
  method: PaymentShift4Method.OPERATION_EXECUTE;
  operation: Operation | null;

  executionData: {
    tokenId: string;
    paymentAmount: number;

    // test if campana requires these
    expiry?: string; // MM/YY -
    holder?: string; // name
  };

  responseType?: OperationEventResponse;
}
