import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { EmemberModule } from '../modules/emember/emember.module';

@Component({
  selector: 'ava-emember-element',
  template: ` <ava-emember /> `,
  standalone: true,
  imports: [CommonModule, RouterOutlet, EmemberModule],
})
export class AvaEmemberCustomElementComponent implements OnInit {
  router = inject(Router);

  ngOnInit(): void {
    this.router.initialNavigation();
  }
}
