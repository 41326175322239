import { CanActivateChildFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MembershipActions } from '@aaa/emember/store-membership';
import { WindowRefService } from '../../share/services/window-ref.service';

export const loadMembership: CanActivateChildFn = () => {
  const windowRef = inject(WindowRefService);
  const store = inject(Store);
  const membershipNumber = windowRef.nativeWindow?.metaData?.user?.memberNumber || '';

  return of(true).pipe(tap(() => store.dispatch(MembershipActions.load({ membershipNumber }))));
};

export default loadMembership;
