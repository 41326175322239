import { CanActivateChildFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { getMembershipOfferPriceQuery } from '@aaa/emember/store-membership-offers';
import { filter, of, withLatestFrom } from 'rxjs';
import {
  getIsLoadingPriceOffer,
  getPriceOfferLevels,
  getPriceOfferQuery,
  PriceOffersActions,
  PriceOffersQuery,
} from '@aaa/emember/store-price-offers';

export const loadPriceOffer: CanActivateChildFn = () => {
  const store = inject(Store);
  const load = (query: PriceOffersQuery) =>
    of(query).pipe(
      tap(() => store.dispatch(PriceOffersActions.load({ query }))),
      switchMap(() => store.select(getIsLoadingPriceOffer).pipe(filter((isLoading) => !isLoading))),
      map(() => true),
    );
  const loaded = (success = true) => of(success);

  return store.select(getMembershipOfferPriceQuery).pipe(
    switchMap((query) =>
      of(query).pipe(withLatestFrom(store.select(getPriceOfferLevels), store.select(getPriceOfferQuery))),
    ),
    switchMap(([query, membershipLevels, stateQuery]) => {
      const emptyMembershipLevels = !membershipLevels.length;
      const queryChanged =
        query.programCode !== stateQuery.programCode ||
        query.couponCode !== stateQuery.couponCode ||
        query.promoCode !== stateQuery.promoCode;

      if (emptyMembershipLevels || queryChanged) {
        return load(query);
      }

      return loaded(true);
    }),
  );
};

export default loadPriceOffer;
