import { Component } from '@angular/core';
import { HiddenTitleModule } from '../modules/hidden-title/hidden-title.module';

@Component({
  selector: 'ava-hidden-title-custom-element',
  template: ` <ava-hidden-title></ava-hidden-title> `,
  standalone: true,
  imports: [HiddenTitleModule],
})
export class HiddenTitleCustomElementComponent {}
