import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  imports: [CommonModule, NzButtonModule],
  standalone: true,
  template: `
    <a nz-button nzType="primary" class="ant-btn ant-btn-primary" href="/account?app=/account">
      <div style="padding: 6px 16px">My Account</div>
    </a>
  `,
  selector: 'ava-my-account-cta',
})
export class MyAccountCtaComponent {}
