import { Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, Subscription } from 'rxjs';
import { AnalyticsEvent, AnalyticsService } from '../../services/analytics';
import firebase from 'firebase/compat/app';
import Timestamp = firebase.firestore.Timestamp;
import { MetaWindow } from '../../interfaces/window';

interface RateLimitEntry {
  source: string;
  messageId: string;
  timeInitial: Timestamp;
  timeLastSent: Timestamp;
  timesLimited: number;
  messageBodies: {
    body: string;
    time: firebase.firestore.Timestamp;
  }[];
}

interface RateLimitDoc {
  rateControlList: RateLimitEntry[];
}

@Component({
  selector: 'ava-notification',
  template: `
    <div [style]="'margin: auto'">
      <div>
        <div [style]="'font-size: 20px; font-weight: 500; padding-top: 20px'">Notifications Feed</div>
        <nz-card
          [nzTitle]="bannerTitle"
          *ngFor="let entry of entries; let entryIndex = index"
          nzBorderless
          class="blue-banner"
          [style]="'width: 100%'"
          [nzBodyStyle]="{ display: 'grid', 'grid-gap': '20px', 'justify-items': 'start' }"
        >
          <ng-template #bannerTitle>
            {{ entry.source }}
            =>
            {{ entry.messageId }}
          </ng-template>
          <div>
            <div>
              Initial Notification:
              {{ entry.timeInitial?.seconds * 1000 | date: 'MM/dd/yy h:mm:ss a' }}
            </div>
            <div>
              Latest Notification:
              {{ entry.timeLastSent?.seconds * 1000 | date: 'MM/dd/yy h:mm:ss a' }}
            </div>
            <div>
              Count:
              {{ entry.timesLimited }}
            </div>
          </div>
          <button
            nz-button
            [nzType]="'primary'"
            (click)="resetRateLimit(entry.source, entry.messageId, false); $event.preventDefault()"
          >
            Clear this entry
          </button>
        </nz-card>
      </div>
    </div>
  `,
})
export class NotificationComponent implements OnInit, OnDestroy {
  @ViewChildren('collapsedWrapper') collapsedWrapper: QueryList<ElementRef> | undefined;
  @ViewChildren('collapsedElement') collapsedElement: QueryList<ElementRef> | undefined;
  entries: RateLimitEntry[] = [];
  notificationRef: AngularFirestoreDocument<RateLimitDoc>;
  notification$: Observable<RateLimitDoc | undefined>;
  notificationSubscription: Subscription;
  window: MetaWindow;

  constructor(
    private afs: AngularFirestore,
    private analyticsService: AnalyticsService,
    private renderer: Renderer2,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow;
    this.notificationRef = afs
      .collection('wss-aaa-web')
      .doc(this.window['metaData'].clubId)
      .collection('apps')
      .doc('notification')
      .collection('rateControlList')
      .doc('list');
    this.notification$ = this.notificationRef.valueChanges();
    this.notificationSubscription = this.notification$.subscribe((rateLimitDoc) => {
      if (rateLimitDoc && rateLimitDoc.rateControlList) {
        this.entries = rateLimitDoc.rateControlList.sort((a, b) => a.timeInitial?.seconds - b.timeInitial?.seconds);

        this.entries.forEach((entry) => {
          const timeLimit = 14 * 24 * 60 * 60; // 14 days
          const timeNow = new Date().getTime() / 1000;
          const timeLastSent = entry.timeLastSent?.seconds;
          if (timeNow - timeLastSent > timeLimit) {
            this.resetRateLimit(entry.source, entry.messageId, true);
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.entries = [];

    const eventCustomElement: AnalyticsEvent = {
      eventCategory: 'Custom Element',
      eventAction: 'Supported',
      eventLabel: '<ava-notification>' + window.location.origin.toLowerCase() + window.location.pathname.toLowerCase(),
      eventValue: 1,
    };
    this.analyticsService.sendEvent(eventCustomElement);
  }

  ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
  }

  resetRateLimit(source: string, messageId: string, confirmed: boolean): void {
    if (!confirmed) {
      confirmed = window.confirm('Reset rate limiting for this message?');
    }
    if (confirmed) {
      const entries = this.entries.filter((entry) => {
        return !(entry.source === source && entry.messageId === messageId);
      });

      this.notificationRef.set({ rateControlList: entries }, { merge: false }).catch(() => {
        //
      });
    }
  }
}
