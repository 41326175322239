import { ActionReducer, MetaReducer, Store } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { JOIN_FEATURE_KEY } from './join/join.selectors';
import { MEMBERSHIP_FEATURE_KEY } from './membership/membership.selectors';
import { AppStore } from './types/store.model';
import { Action } from '@ngrx/store/src/models';
import { storageCompress, storageDecompress } from './utils/storage-session';
import { environment } from '../../environments/environment';

// Todo: need to move into environment
const isTesting = environment.emulator || environment.ngServe;

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    storage: sessionStorage,
    keys: [
      {
        [JOIN_FEATURE_KEY]: {
          encrypt: storageCompress,
          decrypt: storageDecompress,
          filter: ['formValues'],
        },
      },
      {
        [MEMBERSHIP_FEATURE_KEY]: {
          encrypt: storageCompress,
          decrypt: storageDecompress,
          filter: [
            'accountDetails',
            'clubId',
            'membershipNumber',
            'membershipCodesMap',
            'membershipCodes',
            'membershipCards',
          ],
        },
      },
    ],
    rehydrate: true,
  })(reducer);
}

export function debug(reducer: ActionReducer<any>) {
  return (state: Store<AppStore>, action: Action) => {
    const { type, ...payload } = action;
    console.groupCollapsed(type);
    console.log(payload);
    console.groupEnd();
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, ...(isTesting ? [debug] : [])];
