import { Component, ElementRef, HostListener, Inject, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { combineLatest, debounceTime, filter, Observable, of, takeWhile } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { map, tap } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';
import { Block, Field } from '../../block/services/block';
import { FormService } from '../../services/form';
import { DataReadService } from '../../services/data-read';
import { MultiblockService } from '../../services/multiblock';
import { DataWriteService } from '../../services/data-write';
import { RevisionReadService } from '../../services/revision-read';
import { RevisionWriteService } from '../../services/revision-write';
import { BlockState } from '../../block/services/state';
import { GLOBAL_RX_STATE, GlobalState, StateService } from '../../services/state';
import { AnalyticsService } from '../../services/analytics';
import { WebEnvironment } from '../../interfaces/window';
import { NzIconService } from 'ng-zorro-antd/icon';

@Component({
  selector: 'ava-multiblock',
  template: `
    <ng-container *ngIf="globalState$ | async as g">
      <ng-container *ngFor="let memberAlertMessage of g.memberAlertMessages">
        <nz-alert
          nzBanner
          nzShowIcon="false"
          [nzType]="memberAlertMessage.type"
          [nzMessage]="memberAlertMessage.message"
          [nzCloseable]="false"
          class="multiblock-component-alert"
        ></nz-alert>
      </ng-container>
      <!--
        <nz-alert
          nzBanner
          nzType="error"
          nzMessage="Error text text text text text text"
          [nzCloseable]="true"
        ></nz-alert>
        <ng-template #PromoSuccess>
          <div style="display: grid; place-content: center">
              Success! Your Special Discount is Applied.
          </div>
        </ng-template>
      -->

      <!-- show only the template block that is being edited -->
      <div *ngIf="g.adminMode && g.templateEditor && g.templateEditSelected" (click)="pageClicked()">
        <ava-block-wrapper-page-width-wrapper
          [blockId]="g.templateEditSelected.id"
          [stateId]="g.templateEditSelected.id"
          [pageColumnIndex]="0"
          [pageRowId]="g.templateEditSelected.id"
          [pageRowIndex]="0"
          [pageStateId]="stateId"
        ></ava-block-wrapper-page-width-wrapper>
      </div>

      <!-- load all page blocks, page title (hidden), and migrate button (temporarily) -->
      <ng-container *ngIf="pageBlockState$ | async as p">
        <button
          *ngIf="!p.blockValue && g.adminUser && g.allOldBlocksArray && p.migrate"
          nz-button
          nzType="default"
          style="color: red; position: fixed; top: 25px; right: 15px;"
          (click)="transferAllBlocks(g.allOldBlocksArray)"
        >
          migrate {{ g.allOldBlocksArray?.length }} blocks
        </button>

        <!--    <nz-code-editor [ngModel]="p | json" class="editor" style="height: 400px" nzEdit></nz-code-editor>-->

        <div
          *ngIf="g.pageBlock && (!g.adminMode || !g.templateEditor || !g.templateEditSelected)"
          (click)="pageClicked()"
        >
          <!--
                cdkDropList
                id="pageList"
                [cdkDropListConnectedTo]="['createList']"
                cdkDropListDisabled
                (cdkDropListDropped)="drop($event)"
          -->
          <h1
            *ngIf="g.pageBlock.pageTitle"
            style="font-size: 0; width: 1px; height: 1px; display: inline-block; overflow: hidden; position: absolute; clip: rect(1px,1px,1px,1px);"
          >
            {{ g.pageBlock.pageTitle }}
          </h1>
          <!--
                <div
                  *ngIf="g.templateEditor"
                  style="width: 100%; display: flex; justify-content: center"
                >
                  <button
                    nz-button nzSize="small"
                    nzType="primary"
                    (click)="toggleTemplateEditor()"
                  >
                    Exit Template Editor
                  </button>
                </div>
          -->

          <!-- loop through and load all rows in the pageBlock, these must all be of BlockReference type (with a .blockId) -->
          <ava-block-wrapper-page-width-wrapper
            *ngFor="let rowId of rowIds; let rowIndex = index"
            [blockId]="rows[rowId]?.['blockId']"
            [stateId]="rowId"
            [pageColumnIndex]="0"
            [pageRowId]="rowId"
            [pageRowIndex]="rowIndex"
            [pageStateId]="stateId"
          ></ava-block-wrapper-page-width-wrapper>

          <!--
                cdkDrag
          -->
        </div>
      </ng-container>

      <div style="position: relative">
        <ava-join-renew></ava-join-renew>
        <div *ngIf="g.adminMode" style="position: absolute; top: 0; left: 0; height: 100%; width: 100%"></div>
      </div>
    </ng-container>

    <!-- Lazy-load admin module and these components if user has permissions -->
    <!-- These are positioned so that they are not part of the pageBlock cdkDropList -->
    <ng-template #pageToolbar></ng-template>
    <ng-template #editMultiblock></ng-template>
    <ng-template #createPanel></ng-template>
  `,
  providers: [RxState, RxEffects],
})
export class MultiblockComponent {
  @ViewChild('blockContainer', { read: ViewContainerRef }) blockContainerRef: any;
  @ViewChild('pageToolbar', { read: ViewContainerRef }) pageToolbarRef: any;
  @ViewChild('editMultiblock', { read: ViewContainerRef }) editMultiblockRef: any;
  @ViewChild('createPanel', { read: ViewContainerRef }) createPanelRef: any;

  pageBlockState$ = this.pageBlockState.select();
  globalState$ = this.globalState.select();
  stateId: string | undefined;
  formGroup: FormGroup | undefined;
  rowIds: string[] = [];
  rows: { [key: string]: Field } = {};
  rxEffectIds = {
    blockValueFromBlockEditRevision$: 0,
    blockValueFromBlock$: 0,
    formValueChanges$: 0,
  };

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private pageBlockState: RxState<BlockState>,
    private rxEffects: RxEffects,
    private pageService: MultiblockService,
    private dataReadService: DataReadService,
    private dataWriteService: DataWriteService,
    private formService: FormService,
    private revisionReadService: RevisionReadService,
    private revisionWriteService: RevisionWriteService,
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private elementRef: ElementRef,
    private nzIconService: NzIconService,
  ) {
    this.onResize();
    globalState.set({
      blockStateIds: [],
      blockStateIdsChanged: {},
      previewMode: false,
      previewSize: 0,
    });
    pageBlockState.set('type', () => 'PAGE');
    pageBlockState.connect('block', globalState.select('pageBlock'));

    rxEffects.register(this.stateId$);
    rxEffects.register(this.clearEditRevision$);
    rxEffects.register(this.connectBlockValueAndEditRevision$);
    rxEffects.register(this.connectForm$);
    rxEffects.register(this.adminUser$);
    rxEffects.register(this.loadAdminModule$);
    rxEffects.register(this.loadCreatePanelSideEffects$);
    // rxEffects.register(this.connectBlockValue$)
    // rxEffects.register(this.initializeBlockValue$)
    rxEffects.register(this.migrateData$);
    rxEffects.register(this.webEnv$);
  }

  webEnv$ = this.globalState.select('windowMetaData', 'webEnv').pipe(
    tap((webEnv) => {
      /**
       * Enables icons as CDN from millisite.com when running in custom element mode on aaa.com domains,
       * CORS configuration for this is in firebase.json configs.
       * TODO: remove CORS config (from firebase.json) when we stop using these custom-elements.
       *
       * similar icon path modification in core/services/token.ts
       */
      if (webEnv.toUpperCase() === WebEnvironment.PROD || webEnv.toUpperCase() === WebEnvironment.TEST) {
        const domain = this.globalState.get('location', 'origin').replace('.aaa.com', '.millisite.com');
        this.nzIconService.changeAssetsSource(domain);
      }
    }),
  );

  migrateData$ = this.globalState.select('allOldBlocksArray').pipe(
    tap((blocks) => {
      this.pageBlockState.set('migrate', () => !!blocks.length);
    }),
  );

  stateId$ = this.globalState.select('pageBlock', 'id').pipe(
    tap((pageBlockId) => {
      this.stateId = pageBlockId;
      this.globalState.set({
        blockStateIds: [this.stateId],
        blockStateIdsChanged: {},
      });
      /**
       * Initial page load and when initializing a page (both customElementMode and appMode)
       *   load the page blockState into stateService.states
       *   load an undefined form into formService.forms
       *
       * In appMode on route change
       *   reload the forms and states with only this form and states
       *   (preserves the page form and blockState, removes the rest)
       */
      const form: FormGroup | undefined = this.formService.forms[this.pageBlockState.get('block', 'id')];
      if (form) {
        this.formService.resetForms(form);
      }
      const state = this.stateService.states[this.stateId];
      if (state || this.pageBlockState) {
        this.stateService.states = { [this.stateId]: state || this.pageBlockState };
      }
    }),
  );

  /**
   * In appMode, when the route changes
   *   disconnect blockEditRevision
   *   so that it can reconnect to the new page editRevision Doc
   *   when the user enables adminMode
   */
  clearEditRevision$ = this.globalState.select('location', 'pathname').pipe(
    tap(() => {
      this.pageBlockState.connect('blockEditRevision', of(null));
    }),
  );

  /**
   * connect blockEditRevision to observe all editRevision changes in the database for this page
   */
  connectBlockValueAndEditRevision$ = this.globalState.select('adminMode').pipe(
    tap((adminMode) => {
      if (adminMode) {
        if (this.rxEffectIds.blockValueFromBlockEditRevision$) {
          this.rxEffects.unregister(this.rxEffectIds.blockValueFromBlockEditRevision$);
        }
        this.rxEffectIds.blockValueFromBlockEditRevision$ = this.rxEffects.register(
          this.blockValueFromBlockEditRevision$,
        );
        if (this.formGroup) {
          /**
           * connect the form to blockValue (edit version)
           */
          this.pageBlockState.set('blockValue', () => this.formGroup?.getRawValue());
          this.pageBlockState.connect('blockValue', this.formGroup.valueChanges);
        }
      }
      if (!adminMode) {
        if (this.rxEffectIds.blockValueFromBlock$) {
          this.rxEffects.unregister(this.rxEffectIds.blockValueFromBlock$);
        }
        this.rxEffectIds.blockValueFromBlock$ = this.rxEffects.register(this.blockValueFromBlock$);

        /**
         * connect block to blockValue (published version)
         */
        this.pageBlockState.connect('blockValue', this.pageBlockState.select('block'));
      }
      if (adminMode && !this.pageBlockState.get('blockEditRevision')) {
        this.pageBlockState.connect(
          'blockEditRevision',
          this.dataReadService.editRevisionFromDb$(this.globalState.get('pageBlock', 'id')).pipe(
            map((document) => {
              const data = document.payload.data();
              if (!data && !document.payload.metadata.hasPendingWrites) {
                /**
                 * Create editRevision Doc in database because it does not yet exist
                 */
                this.revisionWriteService.updateEditRevisions([this.pageBlockState.get('block')]);
              }
              return data as Block;
            }),
          ),
        );
      }
    }),
  );

  connectForm$ = combineLatest([
    this.pageBlockState.select('blockEditRevision'),
    this.globalState.select('pageBlock', 'id'),
    this.globalState.select('adminMode'),
  ]).pipe(
    filter(([blockEditRevision, pageBlockId, adminMode]) => {
      return !!blockEditRevision && !!pageBlockId && adminMode && !!this.stateId;
    }),
    tap(([blockEditRevision, pageBlockId]) => {
      blockEditRevision = blockEditRevision as Block;
      this.stateService.compareBlockAndEditRevision(
        this.pageBlockState.get('block'),
        blockEditRevision,
        this.stateId as string,
      );

      /**
       * Replace the form when blockEditRevision changes are from another editor session
       */
      const userSessionId = this.globalState.get('userSession', 'sessionId');
      if (this.formGroup) {
        const sessionIdIsDifferent = blockEditRevision.status.session !== userSessionId;
        const formIsDifferent = this.stateService.notDeepEqual(blockEditRevision, this.formGroup.getRawValue());
        if (sessionIdIsDifferent && formIsDifferent) {
          blockEditRevision.status.session = userSessionId;
          this.formService.addForm(this.formService.newForm(blockEditRevision) as FormGroup);
        }
      }

      if (!this.formGroup) {
        blockEditRevision.status.session = userSessionId;
        this.formGroup = this.formService.forms[pageBlockId] = this.formService.newForm(blockEditRevision) as FormGroup;
      }

      if (this.formGroup && !this.rxEffectIds.formValueChanges$) {
        this.rxEffects.unregister(this.rxEffectIds.formValueChanges$);
        this.rxEffectIds.formValueChanges$ = this.rxEffects.register(this.formValueChanges$);
      }
    }),
  );

  adminUser$ = this.globalState.select('adminUser').pipe(
    tap((adminUser) => {
      if (adminUser) {
        this.loadAdminModule();
      }
      // console.log(adminUser)
    }),
  );

  loadAdminModule$ = combineLatest([this.globalState.select('pageBlock'), this.globalState.select('adminUser')]).pipe(
    filter(([pageBlock, adminUser]) => adminUser && !!pageBlock),
    tap(([pageBlock]) => {
      this.loadAdminModule(pageBlock.id);
    }),
  );

  loadCreatePanelSideEffects$ = combineLatest([
    this.globalState.select('pageBlock', 'id'),
    this.globalState.select('loadCreatePanel'),
  ]).pipe(
    takeWhile(([, loadCreatePanel]) => !loadCreatePanel, true),
    filter(([pageBlockId, loadCreatePanel]) => loadCreatePanel && !!pageBlockId),
    tap(([pageBlockId]) => {
      this.loadCreatePanel(pageBlockId);
    }),
  );

  get blockValueFromBlock$(): Observable<any> {
    return this.pageBlockState.select('block').pipe(
      filter((block) => !!block.columns && !!block.rows),
      tap((block) => {
        if (!['/join', '/gift-membership', '/quick-renew'].includes(block.pathName)) {
          this.pageBlockState.set('blockValue', () => block);
          this.rowIds = block.columns[0].rows;
          this.rows = block.rows;
        }
      }),
    );
  }

  get blockValueFromBlockEditRevision$(): Observable<any> {
    return this.pageBlockState.select('blockEditRevision').pipe(
      filter((blockEditRevision) => !!blockEditRevision && !!this.stateId),
      tap((blockEditRevision) => {
        blockEditRevision = blockEditRevision as Block;
        if (!['/join', '/gift-membership', '/quick-renew'].includes(blockEditRevision.pathName)) {
          this.pageBlockState.set('blockValue', () => blockEditRevision as Block);
          this.rowIds = blockEditRevision.columns[0].rows;
          this.rows = blockEditRevision.rows;
          this.stateService.compareBlockAndEditRevision(
            blockEditRevision,
            this.pageBlockState.get('block'),
            this.stateId as string,
          );
        }
      }),
    );
  }

  get formValueChanges$(): Observable<any> {
    if (this.formGroup) {
      return this.formGroup.valueChanges.pipe(
        // filter(formValue => !!formValue && !!this.pageBlockState && this.globalState.get("adminMode")),
        debounceTime(500),
        tap((formValue) => {
          const blockState = this.pageBlockState as RxState<BlockState>;
          const blockEditRevision = blockState.get('blockEditRevision');
          if (blockEditRevision && this.stateService.blocksNotEqual(formValue, blockEditRevision)) {
            this.revisionWriteService.updateEditRevisions([formValue]);
          }
        }),
      );
    }
    return of(null);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.globalState.set(
      'pageWidth',
      () => this.elementRef.nativeElement.getBoundingClientRect().width || this.window.innerWidth,
    );
  }

  loadAdminModule(stateId?: string): void {
    import('./../../modules/multiblock-admin.module').then((m) => m.MultiblockAdminModule);
    import('../../block/toolbar-page/view').then((component) => {
      this.pageToolbarRef.clear();
      const componentRef = this.pageToolbarRef.createComponent(Object.values(component)?.[0] as Type<unknown>);
      if (stateId) {
        componentRef.instance['stateId'] = stateId;
      }
    });
    import('../../block/wrappers/edit-wrapper').then((component) => {
      this.editMultiblockRef.clear();
      const componentRef = this.editMultiblockRef.createComponent(Object.values(component)?.[0] as Type<unknown>);
      if (stateId) {
        componentRef.instance['stateId'] = stateId;
      }
    });
  }

  loadCreatePanel(stateId: string): void {
    import('./../../block/templates/view').then((component) => {
      const componentRef = this.pageToolbarRef.createComponent(Object.values(component)?.[0] as Type<unknown>);
      if (stateId) {
        componentRef.instance['stateId'] = stateId;
      }
    });
  }

  pageClicked(): void {
    // console.log("pageClicked")
    this.pageService.pageClicked();
    this.globalState.set('selectedField', () => '');
  }

  transferAllBlocks(blocks: Block[]): void {
    this.dataWriteService.transferBlocks(blocks).then(() => {
      console.log('finished data transfer of all blocks');
      this.dataWriteService.removeOldBlocks(blocks).then(() => {
        console.log('finished removal of all old blocks');
      });
    });
  }
}
