import { getHash, normalizeQueryParam } from './utils';
import { ExtraOptions } from '../types';

export class FromExternalUrl {
  hash = '';
  queryParams = '';
  appQueryParam = '';

  constructor(externalUrl: string, { routeQueryName }: ExtraOptions) {
    const querySearch = new URLSearchParams(normalizeQueryParam(externalUrl));
    const app = querySearch.get(routeQueryName);

    querySearch.delete(routeQueryName);

    this.hash = getHash(externalUrl);
    this.queryParams = decodeURIComponent(querySearch.toString());
    this.appQueryParam = app && app !== '/' ? app : '/';
  }

  getUrl() {
    return `${this.appQueryParam}${this.queryParams.length ? '?' + this.queryParams : ''}${this.hash}`;
  }
}
