import { Component, ComponentFactory, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'ava-join-renew',
  template: ` <ng-container #MembershipSystem></ng-container> `,
})
export class JoinRenewComponent {
  @ViewChild('MembershipSystem', { read: ViewContainerRef }) membershipSystem:
    | { createComponent: (arg0: ComponentFactory<unknown>) => any }
    | undefined;
}
