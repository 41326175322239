import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';

@NgModule({
  exports: [NotificationComponent],
  declarations: [NotificationComponent],
  imports: [CommonModule, NzButtonModule, NzCardModule],
})
export class NotificationModule {}
