import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinRenewComponent } from './join-renew.component';
// import { RouterModule, Routes } from "@angular/router"
// import { environment } from "../../environments/environment"
// import { Shift4Module } from "./shift4/shift4.module"

/*
const routes: Routes = []
if (environment.emulator) {
  routes.push({
    path: "",
    component: JoinRenewComponent
  })
}
*/

@NgModule({
  declarations: [
    JoinRenewComponent,
    // PrettyJsonPipe
  ],
  exports: [
    JoinRenewComponent,
    // PrettyJsonPipe,
  ],
  imports: [
    CommonModule,
    // RouterModule.forChild(routes),
    // HoosierModule, // TODO: lazyload
    // Shift4Module
  ],
  providers: [
    // Shift4Module,
  ],
})
export class JoinRenewModule {}
