import { Component, Inject, Input } from '@angular/core';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';
import { RxState } from '@rx-angular/state';

@Component({
  selector: 'ava-file-manager',
  templateUrl: './file-manager.component.html',
})
export class FileManagerComponent {
  @Input() directory: string = '';
  globalState$ = this.globalState.select();

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
  ) {}
}
