import { RawCreateParams, ZodRawShape } from 'zod/lib/types';

export function interfaceJoinRenewMembershipMembershipCampana(): string {
  return 'interface-join-renew-membership-membership-campana';
}

import { EventPayloadSchema, Operation } from '@aaa/interface-joinRenew-joinRenewLib';
import { z } from 'zod';

export enum MembershipCampanaMethod {
  IS_ALIVE = 'IS_ALIVE',

  GET_PRICING = 'GET_PRICING',

  MEMBER_LOOKUP = 'MEMBER_LOOKUP',

  GET_COSTS_JOIN = 'GET_COSTS_JOIN',

  OPERATION_EXECUTE = 'OPERATION_EXECUTE',
}

export enum MembershipCampanaLevel {
  Basic = 'Basic',
  Plus = 'Plus',
  PlusRV = 'Plus-RV',
  Premier = 'Premier',
}

// generic
export const MembershipCampanaEventSchema = z
  .object({
    method: z.nativeEnum(MembershipCampanaMethod),
    responseType: z.any(),
  })
  .extend(EventPayloadSchema.shape);
export type MembershipCampanaEvent = z.infer<typeof MembershipCampanaEventSchema>;

export const MembershipCampanaDuesSummarySchema = z.object({
  total: z.number(),
  discounts: z.number(),
  miscCharges: z.number(),
  corporationPays: z.number(),
  tax: z.number(),
  deferredRewardsCredits: z.number(),
  subTotal: z.number(),
  membershipDues: z.number(),
  previousBalance: z.number(),
  unearnedDeferredRewardsCredits: z.number(),
});
export type MembershipCampanaDuesSummary = z.infer<typeof MembershipCampanaDuesSummarySchema>;

export const MembershipCampanaServiceLevelSchema = z.object({
  balanceIncludingTax: z.number(),
  cancelDate: z.string().nullable(),
  promotionCodeBasicLevel: z.string(),
  level: z.nativeEnum(MembershipCampanaLevel),
  promotionCode: z.string(),
  tax: z.number(),
  aueRepresentative: z.string().nullable(),
  rateType: z.string(),
  balance: z.number(),
  cueReason: z.string().nullable(),
  personId: z.enum(['00', '01']),
  cancelReason: z.string().nullable(),
  representative: z.string(),
  cueDate: z.string().nullable(),
  startDate: z.string().nullable(),
  atExpiryLevel: z.nativeEnum(MembershipCampanaLevel),
});
export type MembershipCampanaServiceLevel = z.infer<typeof MembershipCampanaServiceLevelSchema>;

// reused structs
export const MembershipCampanaSessionResponseSchema = z.object({
  sessionId: z.string(),
  data: z.object({
    membership: z.object({
      status: z.number(),
      data: z.any(), // todo - can grab this definition from swagger
    }),
    members: z.object({
      status: z.number(),
      data: z.any(), // todo - can grab this definition from swagger
    }),
    level: z.object({
      status: z.number(),
      data: z.object({
        serviceLevels: z.array(MembershipCampanaServiceLevelSchema),
        duesSummary: MembershipCampanaDuesSummarySchema,
      }), // todo - can grab this definition from swagger
    }),
    dues: z.object({
      status: z.number(),
      data: MembershipCampanaDuesSummarySchema,
    }),
  }),
});
export type MembershipCampanaSessionResponse = z.infer<typeof MembershipCampanaSessionResponseSchema>;

// campana data definitions

/*{ //PERSON
        "birthDate": "1997-01-01",
        "clubJoinDate": "2007-01-01",
        "firstName": "Johneiio",
        "gender": "M",
        "lastName": "Doedthne",
        "previousMemberNumber": "",
        "salutation": "MR"
}*/
export const MembershipCampanaPersonSchema = z.object({
  email: z.string().optional(), //NOT part of campana.person, this is managed in a seperate call
  birthDate: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  gender: z.string(),
  clubJoinDate: z.string().optional(),
  cueDate: z.string().optional(),
  previousMemberNumber: z.string().optional(),
  salutation: z.string().optional(),
});
export type MembershipCampanaPerson = z.infer<typeof MembershipCampanaPersonSchema>;

/*{ //ADDRESS
        "locality": "CA",
        "postCode": "A1A 1A1",
        "region": "ON",
        "street": [
            "somestreet",
            "apt 123"
        ]
},*/
export const MembershipCampanaAddressSchema = z.object({
  locality: z.string(),
  postCode: z.string(),
  region: z.string(),
  street: z.array(z.string()),
});
export type MembershipCampanaAddress = z.infer<typeof MembershipCampanaAddressSchema>;

// Get Pricing (used for comparison chart)
export const MembershipCampanaGetPricingResponseSchema = z.object({
  pricingData: z.array(
    z.object({
      price: z.number(),
      personId: z.enum(['00', '01']),
      tax: z.number(),
      serviceLevel: z.nativeEnum(MembershipCampanaLevel), // move to named type
      priceIncludingTax: z.number(),
    })
  ), // can get from swagger
});
export type MembershipCampanaGetPricingResponse = z.infer<typeof MembershipCampanaGetPricingResponseSchema>;

export const MembershipCampanaGetPricingEventSchema = z
  .object({
    method: z.enum([MembershipCampanaMethod.GET_PRICING]),
    responseType: MembershipCampanaGetPricingResponseSchema.optional(),
  })
  .extend(MembershipCampanaEventSchema.shape);
export type MembershipCampanaGetPricingEvent = z.infer<typeof MembershipCampanaGetPricingEventSchema>;

// Member Lookup
export const MembershipCampanaMemberLookupResponseSchema = z.object({
  executionData: z.string(),
  sessionResponse: MembershipCampanaSessionResponseSchema,
});
export type MembershipCampanaMemberLookupResponse = z.infer<typeof MembershipCampanaMemberLookupResponseSchema>;

export const MembershipCampanaMemberLookupEventSchema = z
  .object({
    method: z.enum([MembershipCampanaMethod.MEMBER_LOOKUP]),
    responseType: MembershipCampanaMemberLookupResponseSchema.optional(),

    memberNumber: z.string(),
  })
  .extend(MembershipCampanaEventSchema.shape);
export type MembershipCampanaMemberLookupEvent = z.infer<typeof MembershipCampanaMemberLookupEventSchema>;

// Join
export const MembershipCampanaGetCostsJoinResponseSchema = z.object({
  executionData: z.string(),
  sessionResponse: MembershipCampanaSessionResponseSchema,
});
export type MembershipCampanaGetCostsJoinResponse = z.infer<typeof MembershipCampanaGetCostsJoinResponseSchema>;

export const MembershipCampanaGetCostsJoinEventSchema = z
  .object({
    method: z.enum([MembershipCampanaMethod.GET_COSTS_JOIN]),
    responseType: MembershipCampanaGetCostsJoinResponseSchema.optional(),

    address: MembershipCampanaAddressSchema,
    primary: MembershipCampanaPersonSchema,
    associates: z.array(MembershipCampanaPersonSchema).optional(),

    couponCode: z.any().optional(),
    promotionCode: z.any().optional(),
    autoRenew: z.boolean(),
    level: z.nativeEnum(MembershipCampanaLevel),

    loginCredentials: z.object({
      encryptedPassword: z.string()
    })
  })
  .extend(MembershipCampanaEventSchema.shape);
export type MembershipCampanaGetCostsJoinEvent = z.infer<typeof MembershipCampanaGetCostsJoinEventSchema>;

// Operation Execute
export const MembershipCampanaOperationExecuteResponseSchema = z.object({
  memberNumber: z.string().optional(),
  campanaResponse: z.any().optional(),
  drupalResponse: z.string().optional()
});
export type MembershipCampanaOperationExecuteResponse = z.infer<typeof MembershipCampanaOperationExecuteResponseSchema>;

export const MembershipCampanaOperationExecuteEventSchema = z
  .object({
    method: z.enum([MembershipCampanaMethod.OPERATION_EXECUTE]),
    operation: z.nativeEnum(Operation),
    responseType: MembershipCampanaOperationExecuteResponseSchema.optional(),

    executionData: z.string(),
    payment: z.any().optional(), // not supplied by ui
  })
  .extend(MembershipCampanaEventSchema.shape);
export type MembershipCampanaOperationExecuteEvent = z.infer<typeof MembershipCampanaOperationExecuteEventSchema>;

function passthroughObject<S extends ZodRawShape>(shape: S, params?: RawCreateParams) {
  //passthrough only shallow as of zod 3, aug 2023
  //https://github.com/colinhacks/zod/issues/2655
  return z.object(shape, params).passthrough();
}
