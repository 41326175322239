<ng-container *ngIf="globalState$ | async as g">
  <!--
  <button
    nz-button
    (click)="listType === 'picture' ? listType = 'picture-card' : listType === 'picture-card' ? listType = 'text' : listType = 'picture'"
  >
    {{ listType }}
  </button>
  <button
    nz-button
    (click)="showUploadButton = !showUploadButton"
  >
    upload {{ showUploadButton ? "enabled" : "disabled" }}
  </button>
  -->
  <div style="margin: 5px 0">
    <!--
    [nzBeforeUpload]="beforeUpload"
-->
    <nz-upload
      [nzCustomRequest]="handleUpload"
      [nzListType]="listType"
      [(nzFileList)]="uploadFileList"
      [nzRemove]="handleRemove"
      [nzShowUploadList]="showUploadList"
      [nzShowButton]="showUploadButton"
      [nzPreview]="handlePreview"
    >
      <button nz-button nzType="primary" *ngIf="listType !== 'picture-card'" (click)="$event.preventDefault()">
        <i nz-icon nzType="upload"></i>
        Upload
      </button>
      <div *ngIf="listType === 'picture-card'">
        <i nz-icon nzType="plus"></i>
        <div style="margin-top: 8px">Upload</div>
      </div>
    </nz-upload>
  </div>

  <div [style]="'margin: 5px 0'">
    <input
      placeholder="search image list"
      nz-input
      [(ngModel)]="inputValue"
      (ngModelChange)="onChange($event)"
      [nzAutocomplete]="auto"
    />
    <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
  </div>

  <div [style]="'margin: 5px 0'">
    <button
      nz-button
      [nzType]="showList ? 'default' : 'primary'"
      (click)="showList = !showList; $event.preventDefault()"
      style="margin-right: 10px"
    >
      Show Image List
    </button>
    <button
      nz-button
      [nzType]="showInstructions ? 'default' : 'primary'"
      (click)="showInstructions = !showInstructions; $event.preventDefault()"
      style="margin-right: 10px"
    >
      Show Instructions
    </button>
  </div>

  <div *ngIf="showList" style="margin: 5px 0">
    <nz-list nzBordered nzSize="small">
      <!--    <nz-list-header>Header</nz-list-header>-->
      <nz-list-item *ngFor="let file of fileList; let index = index" (click)="handlePreview(file)">
        <ul nz-list-item-actions *ngIf="g.adminUser">
          <nz-list-item-action>
            <i
              nz-icon
              nzType="delete"
              nzTheme="outline"
              style="cursor: pointer"
              (click)="fileDelete($event, file, index)"
            ></i>
          </nz-list-item-action>
        </ul>
        <div style="display: flex">
          <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
          <div style="padding: 0 8px">
            {{ file.name }}
          </div>
        </div>
      </nz-list-item>
      <!--    <nz-list-footer>Footer</nz-list-footer>-->
    </nz-list>
  </div>

  <nz-modal
    [(nzVisible)]="showFilePreview"
    (nzOnCancel)="handleFilePreview()"
    (nzOnOk)="handleFilePreview()"
    [nzFooter]="null"
    nzWidth="90%"
  >
    <ava-file-manager-preview *nzModalContent [file]="fileManagerPreviewFile"></ava-file-manager-preview>
  </nz-modal>

  <nz-modal
    [(nzVisible)]="showInstructions"
    (nzOnCancel)="handleInstructions()"
    (nzOnOk)="handleInstructions()"
    [nzFooter]="null"
    [nzBodyStyle]="{ padding: '30px' }"
  >
    <div *nzModalContent>
      <div>To add an image to this page you can either upload a new file or use an existing file.</div>
      <div style="margin: 10px">There are 3 ways to select an image.</div>

      <div style="font-weight: 900; margin: 10px">Upload a New Image</div>
      <ol>
        <li>Click on the [Upload] button and choose a file from your computer to upload.</li>
        <li>Your file will appear below the [Upload] button if the upload was successful.</li>
        <li>Select your file to open a preview popup</li>
        <ng-container *ngTemplateOutlet="instructions"></ng-container>
      </ol>

      <div style="font-weight: 900; margin: 10px">Find an Existing Image with the Search</div>
      <ol>
        <li>Under the [Upload] button is a search field for existing files. Type the name of image.</li>
        <li>Choose an image from the search results to open a preview popup.</li>
        <ng-container *ngTemplateOutlet="instructions"></ng-container>
      </ol>

      <div style="font-weight: 900; margin: 10px">Find an Existing Image from the Image List</div>
      <ol>
        <li>Under the search field is a button for Show Image List. Click the button.</li>
        <li>Choose an image from the Image List to open a preview popup.</li>
        <ng-container *ngTemplateOutlet="instructions"></ng-container>
      </ol>
    </div>
  </nz-modal>

  <ng-template #instructions>
    <li>Copy the full url of the image starting with https://www.wssfiles.com/</li>
    <li>Paste the full url strand into the Image field below [Show Image List] button (and the Image List if open).</li>
  </ng-template>

  <!--<pre>-->
  <!--{{ fileList | json }}-->
  <!--</pre>-->

  <!--
  <section class="control-bar" *ngIf="uploadState | async; let state">
    <button (click)="task.pause()" [disabled]="state === 'paused'">
      Pause
    </button>
    <button (click)="task.cancel()" [disabled]="!(state === 'paused' || state === 'running')">
      Cancel
    </button>
    <button (click)="task.resume()" [disabled]="state === 'running'">
      Resume
    </button>
  </section>
  -->

  <!--<progress [style]="'width: 100%'" max="100" [value]="uploadProgress.percent"></progress>-->
</ng-container>
