import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiblockViewModule } from '../multiblock-view.module';

import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDrawerModule, NzDrawerService } from 'ng-zorro-antd/drawer';
import { AutoFocusDirective } from '../../directives/auto-focus';
import { PrettyJsonPipe } from '../../pipes/pretty-json';
import { StringReplacePipe } from '../../pipes/string-replace.pipe';
import { AnalyticsService } from '../../services/analytics';
import { NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpErrorInterceptorProvider } from '../../services/http-error-interceptor';
import { SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { environment } from '../../../environments/environment';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { CONFIG as AngularFireAnalytics_CONFIG } from '@angular/fire/compat/analytics';
import { RxState } from '@rx-angular/state';
import { HOOSIER_RX_STATE, HoosierState } from '../join-renew/hoosier/hoosier.service';
import { RxEffects } from '@rx-angular/state/effects';
import { UserTrackingService } from '@angular/fire/compat/analytics';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { NzCodeEditorModule } from 'ng-zorro-antd/code-editor';
import { JoinRenewModule } from '../join-renew/join-renew.module';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { OfficeAgentsModule } from '../office-agents/office-agents.module';
import { RouterOutlet } from '@angular/router';
import { MultiblockComponent } from './multiblock.component';

@NgModule({
  declarations: [AutoFocusDirective, PrettyJsonPipe, StringReplacePipe, MultiblockComponent],
  imports: [
    CommonModule,
    MultiblockViewModule,
    NzToolTipModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzRadioModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzDrawerModule,
    DragDropModule,
    OverlayModule,
    NzCodeEditorModule,
    FormsModule,
    JoinRenewModule,
    NzAlertModule,
    OfficeAgentsModule,
    RouterOutlet,
    NzIconModule,
  ],
  exports: [MultiblockComponent],
  providers: [
    AnalyticsService,
    NzIconService,
    NzMessageService,
    NzDrawerService,
    NzNotificationService,
    HttpErrorInterceptorProvider,
    {
      provide: APP_BASE_HREF,
      useValue: window.baseHref,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.emulator
        ? {
            host: 'localhost:8080',
            ssl: false,
          }
        : undefined,
    },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: AngularFireAnalytics_CONFIG,
      useValue: {
        send_page_view: false,
        anonymize_ip: true,
      },
    },
    {
      provide: HOOSIER_RX_STATE,
      useFactory: () => new RxState<HoosierState>(),
    },
    RxEffects,
    RxState,
    UserTrackingService,
  ],
})
export class MultiblockModule {}
