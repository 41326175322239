import { MembershipCampanaLevel } from '@aaa/interface-joinRenew-membership-membershipCampana';

export const getCampanaTowingMiles = (membershipLevel: string) => {
  switch (membershipLevel) {
    case MembershipCampanaLevel.Basic:
      return 3;
    case MembershipCampanaLevel.Plus:
      return 100;
    case MembershipCampanaLevel.PlusRV:
      return 100;
    case MembershipCampanaLevel.Premier:
      return 200;
    default:
      return 0;
  }
};

export const getCampanaMembershipType = (membershipLevel: string) => {
  switch (membershipLevel) {
    case MembershipCampanaLevel.Basic:
      return 'B';
    case MembershipCampanaLevel.Plus:
      return 'P';
    case MembershipCampanaLevel.PlusRV:
      return 'R';
    case MembershipCampanaLevel.Premier:
      return 'E';
    default:
      return '';
  }
};
