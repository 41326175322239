import { Component } from '@angular/core';
import { BatteryQuoterComponent } from '../modules/battery-quoter/battery-quoter.component';

@Component({
  selector: 'ava-battery-quoter-custom-element',
  template: ` <ava-battery-quoter /> `,
  standalone: true,
  imports: [BatteryQuoterComponent],
})
export class BatteryQuoterCustomElementComponent {}
